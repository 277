import React, { useState } from "react";
import Memory from "../../api/Memory";

export const VisibleActiveSoft = () => {
  const [valueBtn, setValueBtn] = useState(Memory.soft_active ? "Да" : "Нет");
  const [clsOnBtn, setClsOnBtn] = useState(
    Memory.soft_active ? "btn_active_period" : "btn_deactive_period"
  );
  const onFilter = () => {
    if (!Memory.soft_active) {
      Memory.setActiveSoft(true);
      setClsOnBtn("btn_active_period");
      setValueBtn("Да");
    } else {
      Memory.setActiveSoft(false);
      setClsOnBtn("btn_deactive_period");
      setValueBtn("Нет");
    }
  };
  return (
    <div className="text_soft_filter_version">
      Только активные
      <span
        className={clsOnBtn}
        style={{ width: "65px", marginLeft: "5px" }}
        onClick={onFilter}
      >
        {valueBtn}
      </span>
    </div>
  );
};
