import React, { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Productions from "../components/company/Productions";
import ApiData from "../api/ApiData";
import Loader from "../components/Loader";
import { observer } from "mobx-react-lite";
import useValidationPhone from "../hooks/useValidationPhone";
import { ApiINNCompany } from "../api/ApiINNCompany";
import { EditBtn } from "../components/UI/EditBtn";
import { AllSoftCompany } from "../components/soft/AllSoftCompany";
import { ComponentLicensingCardCompamy } from "../components/licensing/ComponentLicensingCardCompamy";
import { NumberCompany } from "../components/company/NumberCompany";
import { FilterBarClient } from "../components/company/FilterBarClient";
import { InputEditVer1 } from "../components/UI/InputEditVer1";
import { ButtonFunctional } from "../components/UI/ButtonFunctional";
import { FilterByDay } from "../components/company/FilterByDay";
import FilterData from "../api/FilterData";
import User from "../api/User";
import Media from "../api/Media";
import { ChartContainer } from "../components/chart/ChartContainer";
import { FilterByVersion } from "../components/company/FilterByVersion";
import { SubscriptionComponentCard } from "../components/subscription/SubscriptionComponentCard";
import RolesState from "../api/RolesState";
import { CompanentNoRigthLicCardCompany } from "../components/licensing/CompanentNoRigthLicCardCompany";
import { BarClient } from "../components/company/BarClient";
import config from "../config.json";
import { AnalyticComponent } from "../components/frame/analytic/AnalyticComponent";

const CompanyCard = observer(() => {
  const refSoftCompany = useRef(null);
  // Переменные компании
  const { inn, id } = useParams();
  const [nameCompany, setNameCompany] = useState("");
  const [kpp, setKpp] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [phone, setPhone] = useState("");
  const validPhone = useValidationPhone(phone);
  const [contactRepresentative, setContactRepresentative] = useState("");
  const [descriptions, setDescriptions] = useState("");
  const [idSearch, setIdSearch] = useState("");

  // Флаги
  const [edit, setEdit] = useState(false);
  const [analyticsFlaf, setAnalyticsFlaf] = useState(false);
  const [softFlaf, setSoftFlaf] = useState(true);
  const [newProductionFlag, setNewProductionFlag] = useState(false);
  // Стили
  const [classBtnNavAnalytics, setClassBtnNavAnalytics] =
    useState("nav-card-btn");
  const [classBtnNavSoft, setClassBtnNavSoft] = useState("nav-card-btn-active");
  // Производство и СОФТ
  // ИНН
  const [loadingCompanyINN, setLoadingCompanyINN] = useState(false);

  const navSoft = () => {
    setClassBtnNavSoft("nav-card-btn-active");
    setClassBtnNavAnalytics("nav-card-btn");
    setAnalyticsFlaf(false);
    setSoftFlaf(true);
  };
  // const refAnalytic = useRef();
  const navAnalytics = () => {
    setClassBtnNavSoft("nav-card-btn");
    setClassBtnNavAnalytics("nav-card-btn-active");
    setAnalyticsFlaf(true);
    setSoftFlaf(false);
    // const parentMessage = {
    //   token: JSON.parse(localStorage.getItem("User"))["access_token"],
    //   id_company: ApiData.company.id,
    // };
    // setTimeout(() => {
    //   refAnalytic.current.contentWindow.postMessage(parentMessage, "*");
    // }, 500);
  };

  // Получить инфо о компании начало

  async function getDataFunc() {
    await ApiData.getDataCompanyCard(`company/${inn}`);
  }

  // Прослушиваем аналитику
  // window.onmessage = (message) => {
  //   if (message.data.name === "analytic") {
  //     console.log(message.data.body);
  //     const parentMessage = {
  //       token: JSON.parse(localStorage.getItem("User"))["access_token"],
  //       id_company: ApiData.company.id,
  //     };
  //     refAnalytic.current.contentWindow.postMessage(parentMessage, "*");
  //   }
  // };

  useEffect(() => {
    getDataFunc();
  }, [!edit]);

  const onClickCancel = () => {
    setNewProductionFlag(false);
    ApiData.getProduction();
  };

  const showEditor = () => {
    !edit ? setEdit(true) : setEdit(false);
  };

  const editCompany = () => {
    let dataCompany = {
      id: ApiData.company.id,
    };

    if (nameCompany !== "") {
      dataCompany["name_company"] = nameCompany;
    }
    if (kpp !== "") {
      dataCompany["kpp"] = kpp;
    }
    if (companyAddress !== "") {
      dataCompany["address_company"] = companyAddress;
    }
    if (phone !== "") {
      dataCompany["phone"] = phone;
    }
    if (contactRepresentative !== "") {
      dataCompany["contact_representative"] = contactRepresentative;
    }
    if (descriptions !== "") {
      dataCompany["descriptions"] = descriptions;
    }
    console.log("dataCompany", dataCompany);
    ApiData.updateCompany(dataCompany);
    setEdit(false);
  };

  // if (User.user.permission.includes("get_company_id") || User.user.is_superuser) {

  // Фильтр по площадкам

  // Вкл/откл фильтр по версияс
  const [versionFilterOn, setVersionFilterOn] = useState(
    sessionStorage.getItem("soft_version") ? true : false
  );

  //

  const getSoftProductionFunc = (e) => {
    FilterData.showAllClients();
    setTimeout(() => {
      ApiData.thisProduction(e.id);
      FilterData.sortSoftProduction(e.id);
    }, 200);
    if (Media.mobile) {
      setTimeout(() => {
        return refSoftCompany.current.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  if (RolesState.company_id) {
    return (
      <div className="main-page">
        {!ApiData.isLoading ? (
          <>
            {!edit ? (
              <div className="title_container_btn_edit">
                {RolesState.patch_company ? (
                  <EditBtn onClick={showEditor} text="Редактировать" />
                ) : (
                  <></>
                )}

                <h1 className="main-title">{ApiData.company.name_company}</h1>
              </div>
            ) : (
              <>
                {" "}
                <div className="title_container_btn_edit">
                  <EditBtn
                    onClick={showEditor}
                    text="Отменить редактирование"
                  />
                  <ul>
                    <li>
                      <h1> Название: </h1>
                    </li>
                    <li>
                      <InputEditVer1
                        style={{ width: "250%" }}
                        placeholder={ApiData.company.name_company}
                        value={nameCompany}
                        onChange={(e) => setNameCompany(e.target.value)}
                      />
                    </li>
                  </ul>
                </div>
              </>
            )}
            <div className="card-flex-container">
              <div className="card-flex-container_v2">
                <div className="clm_50 info_company">
                  {/* О компании */}
                  <div className=" ">
                    {!edit ? (
                      <>
                        {" "}
                        <ul>
                          <li className="li_title mrg-btm-10">
                            Реквизиты компании
                          </li>

                          <li className="card-text-item">
                            ИНН: {ApiData.company.inn} / <span>КПП: </span>
                            <span>{ApiData.company.kpp}</span>
                          </li>
                          <li className="card-text-item">
                            <span>Адрес: </span>
                            {ApiData.company.address_company}
                          </li>
                          <li className="card-text-item">
                            <span>
                              Контактное лицо:{" "}
                              {ApiData.company.contact_representative}
                            </span>
                          </li>
                          <li className="card-text-item">
                            <span>Телефон: {ApiData.company.phone}</span>
                          </li>
                          <li className="card-text-item">Описание:</li>
                          <li className="card-text-item">
                            {ApiData.company.descriptions !== "[NULL]"
                              ? ApiData.company.descriptions
                              : "Описания нет"}
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        {" "}
                        <ul>
                          <li>
                            ИНН: {ApiData.company.inn}{" "}
                            <ApiINNCompany
                              inn={inn}
                              setNameCompany={setNameCompany}
                              setCompanyAddress={setCompanyAddress}
                              setKpp={setKpp}
                              setLoadingCompanyINN={setLoadingCompanyINN}
                            />
                          </li>
                          <li className="mrg-top-12">КПП:</li>
                          <li>
                            <InputEditVer1
                              style={{ width: "50%" }}
                              placeholder={ApiData.company.kpp}
                              value={kpp}
                              onChange={(e) => setKpp(e.target.value)}
                            />
                          </li>
                          <li className="mrg-top-12">Адрес:</li>
                          <li>
                            <InputEditVer1
                              style={{ width: "100%" }}
                              placeholder={ApiData.company.address_company}
                              value={companyAddress}
                              onChange={(e) =>
                                setCompanyAddress(e.target.value)
                              }
                            />
                          </li>
                          <li className="mrg-top-12">Контактное лицо:</li>
                          <li>
                            <InputEditVer1
                              style={{ width: "100%" }}
                              placeholder={
                                ApiData.company.contact_representative
                              }
                              value={contactRepresentative}
                              onChange={(e) =>
                                setContactRepresentative(e.target.value)
                              }
                            />
                          </li>
                          <li className="mrg-top-12">Телефон:</li>
                          <li>
                            <InputEditVer1
                              style={{ width: "100%" }}
                              placeholder={ApiData.company.phone}
                              value={validPhone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </li>
                          <li className="mrg-top-12">Описание:</li>
                          <li className="card-text-item">
                            <textarea
                              className="comment"
                              placeholder="Введите дополнительное описание или комментарии"
                              value={descriptions}
                              onChange={(e) => setDescriptions(e.target.value)}
                            ></textarea>
                          </li>
                          <li className="mrg-top-100">
                            <ButtonFunctional onClick={editCompany}>
                              Подтвердить изменения
                            </ButtonFunctional>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div className="clm_50">
                  {/* Площадки */}
                  {RolesState.production_id ? (
                    <div className="info_company">
                      {" "}
                      {!edit ? (
                        <Productions
                          getSoftProductionFunc={getSoftProductionFunc}
                          onClickCancel={onClickCancel}
                          newProductionFlag={newProductionFlag}
                          setNewProductionFlag={setNewProductionFlag}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {!edit ? (
              <>
                <div className="card-flex-container">
                  <div className=" card-flex-container_v2">
                    {RolesState.license_id ? (
                      <ComponentLicensingCardCompamy />
                    ) : (
                      <CompanentNoRigthLicCardCompany>
                        Нет прав для просмотра лицензий
                      </CompanentNoRigthLicCardCompany>
                    )}
                    <div className="container_card_company_block_number_company">
                      {RolesState.subscription_id ? (
                        <SubscriptionComponentCard />
                      ) : (
                        <p className="no_right_text">
                          Нет прав для просмотра лицензий
                        </p>
                      )}

                      <NumberCompany />
                    </div>
                  </div>
                </div>

                {RolesState.soft_company_id ? (
                  <>
                    <div className="company_container_submenu">
                      <div className="company_container_submenu_nav">
                        <span className={classBtnNavSoft} onClick={navSoft}>
                          Софт
                        </span>
                        <span
                          onClick={navAnalytics}
                          className={classBtnNavAnalytics}
                        >
                          Аналитика
                        </span>
                      </div>
                      {softFlaf ? (
                        <BarClient
                          versionFilterOn={versionFilterOn}
                          setVersionFilterOn={setVersionFilterOn}
                          setIdSearch={setIdSearch}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {softFlaf ? (
                      <div ref={refSoftCompany}>
                        <AllSoftCompany
                          setIdSearch={setIdSearch}
                          idSearch={idSearch}
                        />
                      </div>
                    ) : (
                      <></>
                      // <ChartContainer />
                    )}

                    {analyticsFlaf ? (
                      <AnalyticComponent analyticsFlaf={analyticsFlaf} />
                    ) : (
                      // <div className="analytica_container">
                      //   <iframe
                      //   className="analytica_frame"
                      //     title="Аналитика"
                      //     src="http://10.76.10.31:3050/"
                      //     // width="100%"
                      //     // height="100vh"
                      //     // sandbox=""
                      //     ref={refAnalytic}
                      //   />
                      // </div>
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }

  // })
});

export { CompanyCard };
