import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import ApiData from "../../api/ApiData";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import Msg from "../../api/Msg";

export const GenerationSubscription = observer(
  ({ title, flag, func, newFlag }) => {
    const [period, setPeriod] = useState(30);
    const [description, setDescription] = useState("");
    const [period3Cls, setPeriod3Cls] = useState("btn_deactive_period");
    const [period7Cls, setPeriod7Cls] = useState("btn_deactive_period");
    const [period14Cls, setPeriod14Cls] = useState("btn_deactive_period");
    const [period30Cls, setPeriod30Cls] = useState("btn_active_period");
    const [period90Cls, setPeriod90Cls] = useState("btn_deactive_period");
    const [period180Cls, setPeriod180Cls] = useState("btn_deactive_period");
    const [period365Cls, setPeriod365Cls] = useState("btn_deactive_period");

    const [calendar, setCalendar] = useState("");
    const [minDay, setMinDay] = useState("");

    useEffect(() => {
      getTodayDate();
      setCalendarFunc(30);
    }, []);

    const getTodayDate = () => {
      let month;
      let day;
      let d = new Date();
      d.setDate(d.getDate() + 1);
      d.setMonth(d.getMonth() + 1);
      // console.log(d.getMonth());
      if (d.getMonth() < 10 && d.getMonth() !== 0) {
        month = "0" + d.getMonth();
      } else if (d.getMonth() === 0) {
        month = "12";
      } else {
        month = d.getMonth();
      }
      if (d.getDate() < 10) {
        day = "0" + d.getDate();
      } else {
        day = d.getDate();
      }
      setMinDay(d.getFullYear() + "-" + month + "-" + day);
    };

    // Получение даты и запись в календарь
    const setCalendarFunc = (period) => {
      const todayDate = new Date();
      const periodDate = new Date();
      periodDate.setDate(todayDate.getDate() + Number(period));
      // Отображение выбранной даты в календаре
      let month;
      let day;
      let yare;
      periodDate.setDate(periodDate.getDate());
      periodDate.setMonth(periodDate.getMonth() + 1);
      if (periodDate.getMonth() < 10 && periodDate.getMonth() !== 0) {
        month = "0" + periodDate.getMonth();
      } else if (periodDate.getMonth() === 0) {
        month = "12";
        yare = Number(periodDate.getFullYear()) - 1;
      } else {
        month = periodDate.getMonth();
        yare = periodDate.getFullYear();
      }
      if (periodDate.getDate() < 10) {
        day = "0" + periodDate.getDate();
      } else {
        day = periodDate.getDate();
      }
      setCalendar(yare + "-" + month + "-" + day);
    };
    const handlerPeriod = (e) => {
      setCalendar(e.target.value);
      setPeriod3Cls("btn_deactive_period");
      setPeriod7Cls("btn_deactive_period");
      setPeriod14Cls("btn_deactive_period");
      setPeriod30Cls("btn_deactive_period");
      setPeriod90Cls("btn_deactive_period");
      setPeriod180Cls("btn_deactive_period");
      setPeriod365Cls("btn_deactive_period");

      setPeriod(e.target.textContent);
      // Отображение выбранной даты в календаре
      setCalendarFunc(period);
      if (Number(e.target.textContent) === 3) {
        setCalendarFunc(e.target.textContent);
        setPeriod3Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 7) {
        setCalendarFunc(e.target.textContent);
        setPeriod7Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 14) {
        setCalendarFunc(e.target.textContent);
        setPeriod14Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 30) {
        setCalendarFunc(e.target.textContent);
        setPeriod30Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 90) {
        setCalendarFunc(e.target.textContent);
        setPeriod90Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }

      if (Number(e.target.textContent) === 180) {
        setCalendarFunc(e.target.textContent);
        setPeriod180Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 365) {
        setCalendarFunc(e.target.textContent);
        setPeriod365Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
    };
    const handlerPeriodCalendar = (e) => {
      const todayDate = new Date();
      const periodDate = new Date(e.target.value);
      setPeriod(
        Math.ceil(
          (periodDate.getTime() - todayDate.getTime()) / (24 * 3600 * 1000)
        )
      );
      setCalendarFunc(
        Math.ceil(
          (periodDate.getTime() - todayDate.getTime()) / (24 * 3600 * 1000)
        )
      );
      console.log(
        Math.ceil(
          (periodDate.getTime() - todayDate.getTime()) / (24 * 3600 * 1000)
        )
      );
      console.log(
        Math.ceil(
          (periodDate.getTime() - todayDate.getTime()) / (24 * 3600 * 1000)
        )
      );
    };

    const handlerSubscription = () => {
      if (newFlag) {
        if (description === "")
          Msg.writeMessages("Поле комментария обязательное для заполнения.");
        const data = {
          company_id: ApiData.company.id,
          descriptions: description,
          valid_period: period,
        };
        try {
          func(data);
          flag(false);
        } catch (e) {
          console.log(e);
        }
        console.log(data);
      } else {
        try {
          func(period);
          flag(false);
        } catch (e) {
          console.log(e);
        }
      }
    };

    return (
      <>
        <div className="posi-abs">
          <div className="flex_center">
            <div className="generation_lic_container">
              <h2>{title}</h2>
              <div className="generation_lic_period_block">
                <div className="tabs_lgt_grey_border generation_subs_min_block_left">
                  <span>Выберите срок подписки: </span>
                  <div className="generation_lic_btn_period">
                    <span className={period3Cls} onClick={handlerPeriod}>
                      3
                    </span>
                    <span className={period7Cls} onClick={handlerPeriod}>
                      7
                    </span>
                    <span className={period14Cls} onClick={handlerPeriod}>
                      14
                    </span>
                    <span className={period30Cls} onClick={handlerPeriod}>
                      30
                    </span>
                    <span className={period90Cls} onClick={handlerPeriod}>
                      90
                    </span>
                    <span className={period180Cls} onClick={handlerPeriod}>
                      180
                    </span>
                    <span className={period365Cls} onClick={handlerPeriod}>
                      365
                    </span>
                  </div>

                  <label>
                    <span>Укажите срок вручную:</span>
                    <input
                      value={calendar}
                      onChange={handlerPeriodCalendar}
                      className="calendar"
                      type="date"
                      min={minDay}
                    />
                  </label>
                </div>
              </div>
              {newFlag ? (
                <textarea
                  value={description}
                  placeholder="Укажите номер договора"
                  onChange={(e) => setDescription(e.target.value)}
                  className="generation_lic_text mrg-btm-50"
                ></textarea>
              ) : (
                <></>
              )}
            </div>
            <div className="btn-container-new-company">
              <ButtonFunctional onClick={handlerSubscription}>
                Привязать подписку
              </ButtonFunctional>
              <ButtonFunctional onClick={() => flag(false)}>
                Отмена
              </ButtonFunctional>
            </div>
          </div>
        </div>
      </>
    );
  }
);
