import React, { useEffect, useRef, useState } from "react";
import ApiData from "../../../api/ApiData";
import { observer } from "mobx-react-lite";
import config from "../../../config.json"

export const AnalyticComponent = observer(({ analyticsFlaf }) => {
  const [thisRef, setThisRef] = useState();
  const [heightFrame, setHeightFrame] = useState(300);
  const refAnalytic = useRef();

  useEffect(() => {
    const parentMessage = {
      token: JSON.parse(localStorage.getItem("User"))["access_token"],
      id_company: ApiData.company.id,
    };
    setTimeout(() => {
      console.log(refAnalytic.current);
      if (refAnalytic.current !== null) {
        refAnalytic.current.contentWindow.postMessage(parentMessage, "*");
      }
    }, 1500);
    console.log(thisRef);
  }, [analyticsFlaf]);

  window.onmessage = (message) => {
    if (message.data.name === "analytic") {
      setHeightFrame(message.data.height);
      console.log("window.onmessage ====> frame", message, message.data.height);
      const parentMessage = {
        token: JSON.parse(localStorage.getItem("User"))["access_token"],
        id_company: ApiData.company.id,
      };
      if (refAnalytic.current !== null) {
        refAnalytic.current.contentWindow.postMessage(parentMessage, "*");
      }
    }
  };

  const myWheel = (e) => {
    console.log(e);
    e.target.style.overflow = "hidden";
  };

  return (
    <div
      className="analytica_container"
      style={{ height: `${heightFrame + 200}px` }}
    >
      <iframe
        onClick={myWheel}
        className="analytica_frame"
        title="Аналитика"
        src={config.frame_analytic}
        ref={refAnalytic}
      />
    </div>
  );
});
