import { makeAutoObservable, toJS } from "mobx";
import axios from "axios";
import { req } from "./api.config";
import FilterData from "./FilterData";
import User from "./User";
import config from "../config.json";
import Msg from "./Msg";
import RolesState from "./RolesState";

class getData {
  // Адресс для тестов
  url_server = config.url_api;
  // Переменные авторизации
  isLoadPage = false;
  isAuth = true;
  //
  user = null; // Авторизованный пользователь
  user_profile = null; // Профиль авторизованного
  sizeItem = 2000; // Выгружать элементов
  isLoading = true; //Флаг загрузки списка
  employees = []; // Список сотрудников
  employees_dates = []; // Список сотрудников
  companys = []; //Список компаний
  productions = []; // Список площадок
  this_production = null; //Текущая площадка
  create_company_pass = false; // Флаг успешно созданной компании
  softs = []; //Список софта
  soft_type = []; // Получение имени СОФТа
  data_soft = []; // Изначальные данные списка софта

  datas = []; // Изначальные данные списка компаний и сотрудников
  company = []; // Карточка компании
  employee = [];
  licensing = []; //Массив c лицензиями
  licensing_info = []; //Массив c лицензиями
  licensing_free = []; //Массив c свободными лицензиями
  licensing_all = []; // Массив со всеми лицензиями
  licensing_all_data = []; // Массив со всеми лицензиями
  soft_all = []; // Массив со всем софтом
  soft_all_data = []; // Массив со всем софтом
  clients_soft = []; // Массив с клиентами компании
  clients_soft_data = []; // Массив с клиентами компании
  // licensing_activ_data = []; //Массив с активными лицензиями

  // Переменные дизайна
  this_client_soft_id = ""; // id клиента рабочего места
  // Отчёты
  report_lic_activ_error = []; // Массивы для ошибок
  report_lic_activ_error_data = []; // Массивы для ошибок
  report_graph_all = []; // Отчёт по графикам общий
  report_graph_company = [];
  // Флаги
  observableProfile = true; // Флаг прогрузки профиля в карточке
  observableProduction = true; // Флаг прогрузки площадок в карточке
  observableUser = true; // Флаг прогрузки  страницы админа
  observableEmployee = true; // Флаг прогрузки карточки сотрудника
  observableSoft = true; // Флаг прогрузки софта
  observableReportLic = true; // Флаг прогрузки лицензий по всем компаниям
  observableReportAll = true; // Флаг прогрузки графиков по всем компаниям
  observableReportCompany = true; // Флаг прогрузки графиков компани
  observableSoftAll = true; // Флаг прогрузки графиков по всем
  // Подписки
  subscription = [];
  subscription_data = [];
  min_subscription = 0;
  max_subscription = 30;
  // Центр оповещений
  alert_subscription = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Сбросить isLoading
  isLoadingReset = async () => {
    this.isLoading = true;
  };

  // Авторизация
  getLogin = async (login, password, navigate) => {
    localStorage.removeItem("User");
    const logUser = {
      method: "post",
      maxBodyLength: Infinity,
      url: this.url_server + "auth",
      headers: {
        "Content-Type": "application/json",
      },
      data: { email: login, password: password },
    };

    await axios(logUser)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success) {
          localStorage.setItem("User", JSON.stringify(res.data["data"]));
          User.user = res.data["data"];
          this.isAuth = true;
          await RolesState.readRight();
          navigate("/company");
        } else {
          Msg.writeMessages(`Авторизация не удалась: ${res.data.message}`);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.code === "ERR_NETWORK") {
          return Msg.writeMessages(
            "Ошибка: " + e.message + " Попробуйте позже."
          );
        }
        this.isAuth = false;
      });
  };
  // Регистрация
  getRegistration = async (
    password,
    firstName,
    lastName,
    fatherName,
    email,
    phone
  ) => {
    await req
      .post("user", {
        password: password,
        first_name: firstName,
        last_name: lastName,
        father_name: fatherName,
        email: email,
        phone: phone,
      })
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      });
  };
  // КОМПАНИИ
  saveThisCompany = async (company) => {
    this.company = company;
  };
  // get запрос на получение списка компаний
  getDataCompanys = async () => {
    if (!RolesState.companys) return;
    await req
      .get(`company?size=${this.sizeItem}`)
      .then((res) => {
        this.companys = res.data["data"]["request"];
        this.datas = res.data["data"]["request"];
        console.log(res.data["data"]["request"]);
        console.log(res);
        this.isLoading = true;
        this.isAuth = true;
        this.isLoadPage = false;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(async () => {
        // if (!RolesState.l) return;
        // await this.getAllLicensings();
        this.isLoading = false;
      });
    // }
  };
  // Фильтр по компания
  filterDatas = async (search) => {
    if (search) {
      return (this.datas = this.companys.filter(
        (c) =>
          c.inn.toLowerCase().includes(search.toLowerCase()) ||
          c.name_company.toLowerCase().includes(search.toLowerCase())
      ));
    }
    return (this.datas = [...this.companys]);
  };
  // get запрос на получение текущей компании
  getDataCompanyCard = async (url_api) => {
    this.isLoading = true;
    this.clients_soft_data = [];
    this.clients_soft = [];
    this.observableProduction = true;
    await req(url_api)
      .then((res) => {
        // this.isAuth = true;
        this.company = res.data["data"];
        console.log(res.data["data"]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setTimeout(async () => {
          if (RolesState.license_id) {
            await this.getLicensing();
            await this.getClientsLicensing();
          }
          if (RolesState.production_id) {
            await this.getProduction();
          }
          this.isLoading = false;
        }, 2500);
      });
  };
  getDataCompanyCardFree = async () => {
    try {
      const res = await req(`company/${this.company.inn}`);
      console.log(res.data["data"]);
      this.company = res.data["data"];
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(async () => {
        if (RolesState.license_id) {
          // await this.getLicensing();
          // await this.getClientsLicensing();
        }
        if (RolesState.production_id) {
          await this.getProduction();
        }
        this.isLoading = false;
      }, 2500);
    }
  };
  // Получение списка площадок компании
  getProduction = async () => {
    if (localStorage.getItem("User") === null) return;
    this.observableProduction = true;
    if (
      User.user.permission.includes("get_production_id") ||
      User.user.is_superuser
    ) {
      await req
        .get(`production/${this.company.id}?size=${this.sizeItem}`)
        .then((res) => {
          this.productions = res.data["data"]["request"];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.observableProduction = false;
        });
    }
  };
  // Получение текущей площадки
  thisProduction = async (item_id) => {
    return (this.this_production = this.productions.find(
      (p) => p.id === item_id
    ));
  };

  // Создание новой компании
  newCompany = async (dt) => {
    console.log(dt);
    await req
      .post("company", dt)
      .then((res) => {
        this.updateFlagCreateCompany(true);
      })
      .catch((e) => {
        return console.log(e);
      });
  };

  updateFlagCreateCompany = async (bollean) => {
    this.create_company_pass = bollean;
  };
  // Редактирование текущей компании
  updateCompany = async (dataCompany) => {
    await req
      .patch(`company/${this.company.id}`, dataCompany)
      .then(async (res) => {
        await this.getDataCompanyCard(`company/${this.company.inn}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Редактирование текущей площадки
  updateProduction = async (dataProduction) => {
    await req.patch(`production/${this.this_production.id}`, dataProduction);
  };
  // Cоздание новой площадки площадки
  addProduction = async (dataProduction) => {
    await req.post("production", dataProduction).then(async () => {
      await this.getProduction();
    });
  };
  // Редактирование количество юр. лиц
  updateUrNumber = async (number) => {
    await req
      .patch(`company/${this.company.id}`, {
        number_company: Number(number),
      })
      .then(async (res) => {
        await this.getDataCompanyCard(`company/${this.company.inn}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // СОФТ
  getClientSoft = async (client) => {
    try {
      this.this_client_soft_id = this.clients_soft.find(
        (cl) => cl.client_id === client.client_id
      );
    } catch (e) {
      console.log(e);
    }
  };
  // СОТРУДНИКИ
  getDataEmployees = async () => {
    if (localStorage.getItem("User") === null) return;
    this.isLoading = true;
    this.observableUser = true;
    await req(`user?size=${this.sizeItem}`)
      .then((res) => {
        this.isAuth = true;
        this.employees = res.data["data"]["request"];
        this.employees_dates = res.data["data"]["request"];
        console.log(res.data["data"]["request"]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.isLoading = false;
        this.observableUser = false;
      });
    // }
  };

  getPersone = async (url_api) => {
    this.observableEmployee = true;
    User.allRole();
    try {
      const res = await req.get(url_api);
      this.employee = res.data["data"];
      this.observableEmployee = false;
    } catch (e) {
      console.log(e);
    }
  };

  // Редактирование карточки сотрудника
  editPerson = async (dataPersone) => {
    await req
      .patch(`user/${this.employee.id}`, dataPersone)
      .then(async (res) => {
        await this.getPersone(`user/${this.employee.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Сбросить пароль сотруднику
  resetPass = async () => {
    await req
      .patch("user/reset_password", { id: this.employee.id })
      .then(() => {
        Msg.writeMessages("Установлен пароль по умолчанию - 123451234568");
        console.log("Пароль по умолчанию - 123451234568");
      });
  };

  // Получение профиля юзера
  getUser = async () => {
    this.observableProfile = true;
    await req(`my_profile/`)
      .then((res) => {
        this.user_profile = res.data["data"];
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.observableProfile = false;
      });
  };
  // Редактирование профиля
  updateUser = async (dataUser) => {
    await req
      .patch("my_profile", dataUser)
      .then((res) => {
        this.user_profile = res.data["data"];
        User.user.last_name = res.data["data"].last_name;
        User.user.first_name = res.data["data"].first_name;
        User.user.phone = res.data["data"].phone;
        User.user.father_name = res.data["data"].father_name;
        localStorage.setItem("User", JSON.stringify(User.user));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Сменить пароль в профиле
  updatePasswordUser = async (password) => {
    await req
      .patch("my_profile/change_password", { password: String(password) })
      .then((res) => {
        Msg.writeMessages("Пароль изменён");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Лицензии
  // Получение клиент ид компании
  getClientsLicensing = async () => {
    // if (localStorage.getItem("User") === null) return;
    // if (
    //   User.user.permission.includes("get_soft_company_id") ||
    //   (User.user.is_superuser && this.company.id !== undefined)
    // ) {
    await req
      .get(`soft_client/${this.company.id}?size=${this.sizeItem}`)
      .then((res) => {
        if (res.data["data"]["request"] === undefined) {
          return (this.clients_soft = []);
        }
        this.clients_soft = res.data["data"]["request"].filter(
          (element) => element["software_client_rel"] !== null
        );
        this.clients_soft_data = [...this.clients_soft];
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  };
  // Получение лицензий компании
  getLicensing = async () => {
    await req
      .get(`licensing/search/${this.company.id}?size=${this.sizeItem}`)
      .then((res) => {
        this.licensing = res.data["data"]["activ_license"];
        this.licensing = [...this.licensing, ...res.data["data"]["no_activ"]];
        this.licensing_free = res.data["data"]["free_license"];
        this.licensing_info = res.data["data"]["counters"];
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.observableSoft = false;
        setTimeout(async () => {
          await this.overkillSoftClients();
        }, 500);
      });
  };
  // }

  // Перебор клиентов и лицензий
  async overkillSoftClients() {
    if (this.clients_soft !== undefined && this.licensing !== undefined) {
      this.clients_soft.forEach((cl) => {
        const clientLic = this.licensing.find(
          (l) => l.software_client_id === cl.client_id
        );
        if (clientLic) {
          if (clientLic.type_software === "old") {
            cl.valid_period_days = "Обновите";
          } else {
            const this_d = new Date().getFullYear();
            if (new Date(clientLic.activ_data).getFullYear() - this_d) {
              cl.activ_data = "Бессрочно";
            }
            if (clientLic.valid_period_days < 1) {
              cl.valid_period_days = "Истекла";
            } else if (clientLic.valid_period_days > 3650) {
              cl.valid_period_days = "Бессрочная";
            } else {
              cl.valid_period_days = clientLic.valid_period_days;
            }
          }
          cl.activ_data = clientLic.activ_data;
          if (clientLic.is_activ) {
            cl.activated = clientLic.is_activ;
          } else {
            cl.activated = "hold";
          }

          cl.id_license = clientLic.id_license;
          cl.type_software = clientLic.type_software;
        } else {
          cl.activ_data = "";
          cl.valid_period_days = "";
        }
      });
      FilterData.sortFuncSoftVersionAB(this.clients_soft);
      this.clients_soft_data = [...this.clients_soft];
      FilterData.dayFilterLic();
    }
    console.log("this.clients_soft_data", toJS(this.clients_soft_data));
    this.observableSoft = false;
  }
  // Получить весь софт
  getAllSoft = async (flag) => {
    if (this.soft_all.length === 0 || flag) {
      try {
        this.observableSoftAll = true;
        const res = await req.get(`soft_client?size=${9999}`);
        this.soft_all = res.data["data"]["request"];
        this.soft_all_data = res.data["data"]["request"];
      } catch (e) {
        console.log(e);
      } finally {
        this.observableSoftAll = false;
      }
    }
  };
  // Получить все лицензии
  getAllLicensings = async () => {
    // if (localStorage.getItem("User") === null) return;
    // if (
    //   this.licensing_all.length === 0 &&
    //   (User.user.permission.includes("get_all_licensing") ||
    //     User.user.is_superuser)
    // ) {
    //   try {
    //     await req.get(`licensing?size=${9999}`).then((res) => {
    //       this.licensing_all = res.data["data"]["activ_license"];
    //       this.licensing_all_data = res.data["data"];
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
  };
  // Таргет клиента софта
  getTargetClient = async () => {
    this.clients_soft.find(
      (cl) => cl.client_id === this.clients_soft.client_id
    );
  };
  // Отвязать лицензию от клиента
  unlincLic = async () => {
    await req
      .patch(`licensing/unlink`, {
        software_client_id: this.this_client_soft_id.client_id,
      })
      .then(async (res) => {
        setTimeout(async () => {
          await this.getLicensing();
          await this.getClientsLicensing();
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Привязать лицензию к клиенту
  attachLic = async () => {
    try {
      await req.patch(
        `licensing/attach/${Number(FilterData.license.id_license)}`,
        {
          software_client_id: this.this_client_soft_id.client_id,
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(async () => {
        await this.getLicensing();
        await this.getClientsLicensing();
      }, 1000);
    }
  };
  // Сгенерировать лицензии
  generationLicense = async (count, period, version, description) => {
    await req
      .post(`licensing/generate`, {
        license: [
          {
            inn_company: String(this.company.inn),
            count: Number(count),
            period_action: Number(period),
            lite_version: version,
            description: description,
          },
        ],
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setTimeout(async () => {
          await this.getLicensing();
        }, 200);
      });
  };
  // Продлить лицензию

  renewalLic = async (license, period) => {
    await req
      .patch(`licensing/prolongation/${license.id_license}`, {
        period_prolongation: Number(period),
      })
      .then(async () => {
        await this.getClientsLicensing();
      })
      .catch((e) => console.log(e));
  };

  // Проверка лицензии
  checkLic = async (id_license) => {
    try {
      const res = await req.get(`licensing/pin/${id_license}`);
      Msg.writeMessages(
        JSON.stringify({
          Клиент: res.data["data"].software_client_id,
          "Активна до": res.data["data"].activ_data,
          Инфо:
            res.data["data"].type_software !== "old"
              ? res.data["data"].type_software
              : "Используется старая версия лицензи, необходимо обновить лицензию.",
          Комментарий: res.data["data"].description,
          Активность: res.data["data"].is_activ
            ? "активна "
            : "работа лицензии приостановлена",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  // ОТЧЁТЫ

  // Отвязать клиента от компании
  deleteClientID = async (client) => {
    const deleteClient = {
      method: "delete",
      maxBodyLength: Infinity,
      url: this.url_server + "licensing",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("User"))["access_token"]
        }`,
      },
      data: { client_id: client.client_id },
    };

    try {
      const res = await axios(deleteClient);
      setTimeout(() => {
        this.getAllSoft(true);
      }, 1500);
      // console.log(res);
    } catch (e) {
      // Msg.writeMessages(e.data.message);
      return console.log(e);
    } finally {
      // this.getDataCompanyCardFree();
    }
  };

  // Подписки
  postSubscription = async (obj) => {
    try {
      const res = await req.post(`subscription/`, obj);
    } catch (e) {
      console.log(e);
    } finally {
      this.getDataCompanyCardFree();
    }
  };
  prolongationSubscription = async (count) => {
    try {
      await req.patch(
        `subscription/${this.company.subscription_rel[0].id}/prolongation`,
        {
          period_prolongation: count,
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.getDataCompanyCardFree();
    }
  };

  deleteSubscription = async () => {
    const deleteClient = {
      method: "delete",
      maxBodyLength: Infinity,
      url: this.url_server + "subscription",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("User"))["access_token"]
        }`,
      },
      data: { id_subscription: this.company.subscription_rel[0].id },
    };
    try {
      await req(deleteClient);
    } catch (e) {
      console.log(e);
    } finally {
      this.getDataCompanyCardFree();
    }
  };

  // Отчёт по подпискам
  allReportSubscription = async (max) => {
    try {
      const res = await req.get(
        `subscription/valid_subscription?min_period=${this.min_subscription}&max_period=${max}&size=${this.sizeItem}`
      );
      if (res.data["data"].length === 0) return;
      this.alert_subscription = res.data["data"]["request"];
      this.subscription = res.data["data"]["request"];
      this.subscription_data = res.data["data"]["request"];
    } catch (e) {
      console.error(e);
    } finally {
      this.observableReportLic = false;
    }
  };

  // Деактивировать или активировать лицензию
  setActiveLicenseAndDisable = async (id_license, boolean) => {
    try {
      const res = await req.patch(`licensing/set_activity/${id_license}`, {
        is_activ: boolean,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(async () => {
        await this.getLicensing();
        await this.getClientsLicensing();
      }, 500);
    }
  };

  selectMaxSubscription = async (period) => {
    this.max_subscription = period;
  };
}

export default new getData();
