import { Route, Routes, useNavigate } from "react-router-dom";
import "./style/App.css";
import { LayOut } from "./pages/LayOut";
import { LoginPage } from "./pages/LoginPage";
import { PrivateUserAuth } from "./routs/PrivateUserAuth";
import { Company } from "./pages/Company";
import { Employees } from "./pages/Employees";
import { EmploessCard } from "./pages/EmploessCard";
import { CompanyCard } from "./pages/CompanyCard";
import { UserProfile } from "./pages/UserProfile";
import { AllReport } from "./pages/AllReport";
import { AlertCenter } from "./pages/AlertCenter";
import { AdminPanel } from "./pages/AdminPanel";
import { useEffect, useLayoutEffect, useState } from "react";
import ApiData from "./api/ApiData";
import { observer } from "mobx-react-lite";
import Media from "./api/Media";
import RolesState from "./api/RolesState";

const App = observer(() => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    Media.handlerResize(width);
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
      Media.handlerResize(width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!ApiData.isAuth || localStorage.getItem("User") === null) {
      return navigate("/login");
    }
    RolesState.readRight();
  }, [!ApiData.isAuth]);

  return (
    <Routes>
      <Route path="/" element={<LayOut />}>
        <Route index element={<LoginPage />} />
        <Route index path="login" element={<LoginPage />} />
        {ApiData.isAuth ? (
          <Route element={<PrivateUserAuth />}>
            <Route path="adminpanel" element={<AdminPanel />}></Route>
            <Route path="company" element={<Company />} />
            <Route path="employees" element={<Employees />} />
            <Route path="employees/:id" element={<EmploessCard />} />
            <Route path="company/:inn" element={<CompanyCard />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="allreport" element={<AllReport />} />
            <Route path="alertcenter" element={<AlertCenter />} />
          </Route>
        ) : (
          <Route index path="login" element={<LoginPage />} />
        )}
      </Route>
    </Routes>
  );
});

export default App;
