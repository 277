import React from "react";
import { FilterByVersion } from "./FilterByVersion";
import { FilterBarClient } from "./FilterBarClient";
import { VisibleActiveSoft } from "./VisibleActiveSoft";

export const BarClient = ({
  versionFilterOn,
  setVersionFilterOn,
  setIdSearch,
}) => {
  return (
    <div className="bar_filter_client_company">
      <VisibleActiveSoft />
      <FilterByVersion
        versionFilterOn={versionFilterOn}
        setVersionFilterOn={setVersionFilterOn}
      />
      <FilterBarClient
        setIdSearch={setIdSearch}
        setVersionFilterOn={setVersionFilterOn}
      />
    </div>
  );
};
