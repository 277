import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import imgKeyActive from "../../img/icons/icon-key-2.png";
import imgKeyDeactive from "../../img/icons/icon-key-2-deact.png";
import Media from "../../api/Media";
import ApiData from "../../api/ApiData";
import RolesState from "../../api/RolesState";
import Memory from "../../api/Memory";

export const ItemSoftCompany = observer((props) => {
  const [date_sesion, setDateSession] = useState(props.itemsoft.last_session);
  const [subAction, setSubAction] = useState(false);

  useEffect(() => {
    const last_session = date_sesion;
    const d_session = new Date(last_session).toLocaleString();
    setDateSession(d_session);
  }, []);

  const checkLicensing = () => {
    console.log(props.itemsoft);
    ApiData.checkLic(props.itemsoft.id_license);
  };

  const disableLicense = async () => {
    console.log(props.itemsoft);
    ApiData.setActiveLicenseAndDisable(props.itemsoft.id_license, false);
  };
  const enabledLicense = async () => {
    console.log(props.itemsoft);
    ApiData.setActiveLicenseAndDisable(props.itemsoft.id_license, true);
  };
  // props.itemsoft.software_client_rel !== null &&
  // ((props.itemsoft.activ_data !== "" && props.itemsoft.activ_data !== null) &&
  //   !Memory.soft_active
  return props.itemsoft.software_client_rel !== null &&
    (props.itemsoft.activ_data !== "" || !Memory.soft_active) &&
    (props.itemsoft.activ_data !== null || !Memory.soft_active) ? (
    <div
      className={
        props.itemsoft.activated === "hold"
          ? "lic_container_item_hold"
          : props.itemsoft.type_software === "old" &&
            props.itemsoft.activated !== "hold"
          ? "lic_container_item_old"
          : "lic_container_item"
      }
    >
      <span className="soft_list_name tabs_lgt_grey_border padding_7_5">
        {Media.mobile
          ? props.itemsoft.software_client_rel.name.slice(0, 9)
          : props.itemsoft.software_client_rel.name}
      </span>
      <span className="soft_list_client_id tabs_lgt_grey_border padding_7_5">
        {Media.mobile
          ? "..." + props.itemsoft.client_id.substr(-6, 6)
          : props.itemsoft.client_id}
      </span>

      {Media.mobile ? (
        <></>
      ) : (
        <span className="soft_list_last_session tabs_lgt_grey_border padding_7_5">
          {date_sesion}{" "}
        </span>
      )}

      <span className="soft_list_software_version tabs_lgt_grey_border padding_7_5">
        {Media.mobile
          ? props.itemsoft.software_version.slice(0, 8)
          : props.itemsoft.software_version}
      </span>

      {(props.itemsoft.activated || props.itemsoft.activated === "hold") &&
      (RolesState.licen_prol_id || RolesState.unlink_licensing) ? (
        <label
          onClick={() => (subAction ? setSubAction(false) : setSubAction(true))}
          className="visible-container-soft-key soft_list_valid_action tabs_lgt_grey_border"
        >
          <img className="key-btn" src={imgKeyDeactive} alt="" />
          <span className="visible-text-soft-key">
            Действия с привязанной лицензией
          </span>
          {subAction ? (
            <div className="sub_action_client_container_border">
              <ul className="sub_action_client_container">
                <li
                  className="sub_action_client_closed"
                  onClick={() => setSubAction(false)}
                >
                  Х
                </li>
                {RolesState.check_licensing ? (
                  <li onClick={checkLicensing}>Проверить лицензию</li>
                ) : (
                  <></>
                )}
                {RolesState.licen_prol_id ? (
                  <li
                    onClick={() => props.actionClientLicRenewal(props.itemsoft)}
                  >
                    Продлить лицензию
                  </li>
                ) : (
                  <></>
                )}
                {RolesState.patch_activity_license_id &&
                props.itemsoft.activated !== "hold" ? (
                  <li onClick={disableLicense}>Заморозить лицензию</li>
                ) : (
                  <></>
                )}
                {RolesState.patch_activity_license_id &&
                props.itemsoft.activated === "hold" ? (
                  <li onClick={enabledLicense}>Разблокировать лицензию</li>
                ) : (
                  <></>
                )}
                {RolesState.unlink_licensing ? (
                  <li
                    onClick={() => props.actionClientLicRemove(props.itemsoft)}
                  >
                    Отвязать лицензию
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </label>
      ) : (
        <>
          {RolesState.attach_licensing ||
          RolesState.licen_prol_id ||
          RolesState.unlink_licensing ? (
            <label
              onClick={() => props.actionClientLicSet(props.itemsoft)}
              className="visible-container-soft-key soft_list_valid_action tabs_lgt_grey_border"
            >
              <img className="key-btn" src={imgKeyActive} alt="" />
              <ul>
                <li className="visible-text-soft-key">
                  Привязать лицензию к этому клиенту
                </li>
                <li></li>
              </ul>
            </label>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
});
