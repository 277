import { makeAutoObservable } from "mobx";

class memory {
  // Переменные
  var_filter_by_production = null; // фильтр клиентов по площадкам в FilterBarClient
  select_day_period = false;
  day_active_lic = sessionStorage.getItem("day_active_lic");
  // Координаты элементов
  coord_soft_card_company = 0;
  version_reduction = true;
  soft_active = false;

  constructor() {
    makeAutoObservable(this);
  }

  setVersionReduction = async () => {
    this.version_reduction
      ? (this.version_reduction = false)
      : (this.version_reduction = true);
  };

  setActiveSoft = (bollean) => {
    this.soft_active = bollean;
  };

  //   Изменение var_filter_by_production
  funcFilterByProduction(production) {
    this.var_filter_by_production = production;
  }

  // Смена флага который включает фильтр по дням
  funcSelectDayPeriodFlag(flag) {
    this.select_day_period = flag;
    sessionStorage.setItem("day_active_flag", flag);
  }
  // Выбор фильтра по определённым дням
  funcSelectDayPeriod(target) {
    sessionStorage.setItem("day_active_lic", target);
    this.day_active_lic = sessionStorage.getItem("day_active_lic");
  }
  // Смена координат
  writeCoordSoftCardCompany = (coord) => {
    this.coord_soft_card_company = coord;
  };
}
export default new memory();
